import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import { HQkeys, TokenKeys } from '@vezham/ui/dist/contracts/iam'

const useBusiness = {
  reset: () => {
    useVStore.delete(TokenKeys.BUSINESS_REFRESH_TOKEN)
    useVStore.delete(TokenKeys.BUSINESS_ACCESS_TOKEN)
    useVStore.delete(TokenKeys.BUSINESS_EXPIRES_IN)
    useVStore.delete(HQkeys.ACTIVE_BUSINESS)
    return
  },
  update: (data: any) => {
    if (!data) return

    useVStore.set(
      TokenKeys.BUSINESS_ACCESS_TOKEN,
      data[TokenKeys.BUSINESS_ACCESS_TOKEN]
    )
    useVStore.set(
      TokenKeys.BUSINESS_EXPIRES_IN,
      data[TokenKeys.BUSINESS_EXPIRES_IN]
    )
    if (data[TokenKeys.BUSINESS_REFRESH_TOKEN]) {
      useVStore.set(
        TokenKeys.BUSINESS_REFRESH_TOKEN,
        data[TokenKeys.BUSINESS_REFRESH_TOKEN]
      )
    }
  }
}

export default useBusiness
