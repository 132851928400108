import useBusiness from '../../../store/hq/business/vstore'
import usePeople from '../../../store/hq/people/vstore'
import { resetAllToken, resetBusinessToken, sendToken } from './token'

const onMessage = (e: MessageEvent<any>) => {
  const payload = typeof e.data == 'string' ? JSON.parse(e.data) : e.data
  const { method, data } = payload

  switch (method) {
    case 'RESET_ALL_TOKEN':
      resetAllToken()
      break
    case 'RESET_BUSINESS_TOKEN':
      resetBusinessToken()
      break
    case 'GET_TOKEN':
      sendToken(!!data.isInit)
      break
    case 'SAVE_ACCOUNT_TOKEN':
      usePeople.update(data)
      break
    case 'SAVE_ACCOUNT_DC':
      usePeople.updateDC(data)
      break
    case 'SAVE_BUSINESS_TOKEN':
      useBusiness.update(data)
      break
  }
}

export default onMessage
