import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import { HQkeys, TokenKeys } from '@vezham/ui/dist/contracts/iam'

const usePeople = {
  reset: () => {
    useVStore.delete(TokenKeys.ACCOUNT_REFRESH_TOKEN)
    useVStore.delete(TokenKeys.ACCOUNT_ACCESS_TOKEN)
    useVStore.delete(TokenKeys.ACCOUNT_EXPIRES_IN)
    useVStore.delete(TokenKeys.ACCOUNT_DC)
    useVStore.delete(HQkeys.ACTIVE_PEOPLE)
    return
  },
  update: (data: any) => {
    if (!data) return

    useVStore.set(
      TokenKeys.ACCOUNT_ACCESS_TOKEN,
      data[TokenKeys.ACCOUNT_ACCESS_TOKEN]
    )
    useVStore.set(
      TokenKeys.ACCOUNT_EXPIRES_IN,
      data[TokenKeys.ACCOUNT_EXPIRES_IN]
    )
    if (data[TokenKeys.ACCOUNT_REFRESH_TOKEN]) {
      useVStore.set(
        TokenKeys.ACCOUNT_REFRESH_TOKEN,
        data[TokenKeys.ACCOUNT_REFRESH_TOKEN]
      )
    }
  },
  updateDC: (data: any) => {
    if (!data) return

    useVStore.set(TokenKeys.ACCOUNT_DC, data)
  }
}

export default usePeople
