'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

exports.StoreKeys = void 0;
(function (StoreKeys) {
  StoreKeys["REDIRECTED_FROM"] = "redirect-from";
})(exports.StoreKeys || (exports.StoreKeys = {}));
exports.StateKeys = void 0;
(function (StateKeys) {
  StateKeys["KEY"] = "launcher";
  StateKeys["NO_ACTIVE_IAM_ACCOUNT"] = "no_active_iam_account";
  StateKeys["NO_ACTIVE_IAM_BUSINESS"] = "no_active_iam_business";
  StateKeys["INIT_IAM_ACCOUNT_BY_CODE"] = "init_iam_account_by_code";
  StateKeys["INIT_IAM_BUSINESS_BY_CODE"] = "init_iam_business_by_code";
})(exports.StateKeys || (exports.StateKeys = {}));
exports.IFrameKeys = void 0;
(function (IFrameKeys) {
  IFrameKeys["HOST"] = "vezham-iframe-iam";
})(exports.IFrameKeys || (exports.IFrameKeys = {}));
exports.IAMtoken = void 0;
(function (IAMtoken) {
  IAMtoken["REFRESH_TOKEN"] = "rt";
  IAMtoken["ACCESS_TOKEN"] = "at";
  IAMtoken["EXPIRES_IN"] = "ein";
})(exports.IAMtoken || (exports.IAMtoken = {}));
exports.HQkeys = void 0;
(function (HQkeys) {
  HQkeys["ACTIVE_PEOPLE"] = "people_handle";
  HQkeys["ACTIVE_BUSINESS"] = "business_handle";
  HQkeys["ACTIVE_WORKSPACE"] = "workspace_handle";
})(exports.HQkeys || (exports.HQkeys = {}));
exports.TokenKeys = void 0;
(function (TokenKeys) {
  TokenKeys["ACCOUNT_REFRESH_TOKEN"] = "account_rt";
  TokenKeys["ACCOUNT_ACCESS_TOKEN"] = "account_at";
  TokenKeys["ACCOUNT_EXPIRES_IN"] = "account_ein";
  TokenKeys["ACCOUNT_DC"] = "account_dc";
  TokenKeys["BUSINESS_REFRESH_TOKEN"] = "business_rt";
  TokenKeys["BUSINESS_ACCESS_TOKEN"] = "business_at";
  TokenKeys["BUSINESS_EXPIRES_IN"] = "business_ein";
})(exports.TokenKeys || (exports.TokenKeys = {}));
exports.GrantType = void 0;
(function (GrantType) {
  GrantType["AUTH_CODE"] = "authorization_code";
  GrantType["REFRESH_TOKEN"] = "refresh_token";
})(exports.GrantType || (exports.GrantType = {}));
exports.TokenType = void 0;
(function (TokenType) {
  TokenType["NO_TOKEN"] = "NO_TOKEN";
  TokenType["ACCOUNT"] = "ACCOUNT";
  TokenType["BUSINESS"] = "BUSINESS";
})(exports.TokenType || (exports.TokenType = {}));
exports.DomainType = void 0;
(function (DomainType) {
  DomainType["SANDBOX"] = "SANDBOX";
  DomainType["DC"] = "DC";
})(exports.DomainType || (exports.DomainType = {}));
