import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import { TokenKeys } from '@vezham/ui/dist/contracts/iam'

import useBusiness from '../../../store/hq/business/vstore'
import usePeople from '../../../store/hq/people/vstore'
import { sendMessageToParent } from '../utils'

const sendInit = () => {
  const RTaccount = useVStore.get(TokenKeys.ACCOUNT_REFRESH_TOKEN, '')
  const ATaccount = useVStore.get(TokenKeys.ACCOUNT_ACCESS_TOKEN, '')
  const EINaccount = useVStore.get(TokenKeys.ACCOUNT_EXPIRES_IN, '')
  const DCaccount = useVStore.get(TokenKeys.ACCOUNT_DC, '')

  const RTbusiness = useVStore.get(TokenKeys.BUSINESS_REFRESH_TOKEN, '')
  const ATbusiness = useVStore.get(TokenKeys.BUSINESS_ACCESS_TOKEN, '')
  const EINbusiness = useVStore.get(TokenKeys.BUSINESS_EXPIRES_IN, '')

  const data = {
    [TokenKeys.ACCOUNT_REFRESH_TOKEN]: RTaccount,
    [TokenKeys.ACCOUNT_ACCESS_TOKEN]: ATaccount,
    [TokenKeys.ACCOUNT_EXPIRES_IN]: EINaccount,
    [TokenKeys.ACCOUNT_DC]: DCaccount,

    [TokenKeys.BUSINESS_REFRESH_TOKEN]: RTbusiness,
    [TokenKeys.BUSINESS_ACCESS_TOKEN]: ATbusiness,
    [TokenKeys.BUSINESS_EXPIRES_IN]: EINbusiness
  }

  sendMessageToParent({ method: 'INIT_TOKEN', data })
}
const sendReset = (isInit: boolean) => {
  sendMessageToParent({ method: 'RESET_TOKEN', data: { isInit } })
}

export const sendToken = (isInit: boolean) => {
  if (window.self === window.top) {
    return
  }

  const RTaccount = useVStore.get(TokenKeys.ACCOUNT_REFRESH_TOKEN, '')
  if (RTaccount) {
    sendInit()
  } else {
    sendReset(isInit)
  }
}

export const resetAllToken = () => {
  usePeople.reset()
  useBusiness.reset()
  sendMessageToParent({ method: 'RELOAD', data: {} })
}

export const resetBusinessToken = () => {
  useBusiness.reset()
  sendMessageToParent({ method: 'RELOAD', data: {} })
}
