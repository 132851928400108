import onMessage from './onMessage'

export const onIAMmessenge = (e: MessageEvent<any>) => {
  if (
    e.origin != process.env.REACT_APP_ACCOUNTS_URL &&
    e.origin != process.env.REACT_APP_BUSINESS_URL &&
    e.origin != process.env.REACT_APP_HQ_URL
  ) {
    return
  }

  onMessage(e)
}
